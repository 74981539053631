<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    :title="$t('tips.editPassTip')"
    width="500px"
    @closed="handClose"
  >
    <div class="authDig">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item prop="oldpassword">
          <el-input
            show-password
            v-model="form.oldpassword"
            :placeholder="$t('loginInfo.userOldPassTip')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            show-password
            v-model="form.password"
            :placeholder="$t('loginInfo.userNewPassTip')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="repassword">
          <el-input
            show-password
            v-model="form.repassword"
            :placeholder="$t('loginInfo.userPassAgainTip')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">{{
        $t("tips.cancelText")
      }}</el-button>
      <el-button size="small" type="primary" @click="submitForm">{{
        $t("tips.submitText")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import useDialog from "@/hooks/useDialog";
import { Message } from "element-ui";
import { useLang } from "@/hooks/useLang";
import { forgetPass } from "@/api/common";
const { lang } = useLang();
const emit = defineEmits(["close", "resetEmail"]);
const { proxy } = getCurrentInstance();
const { visible, handClose } = useDialog(emit);
const form = reactive({
  oldpassword: null, // 旧密码
  password: "", // 新密码
  repassword: "", // 新密码
});
const rules = ref({
  oldpassword: [
    {
      required: true,
      message: proxy.$t("loginInfo.userOldPassTip"),
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: proxy.$t("loginInfo.userNewPassTip"),
      trigger: "blur",
    },
  ],
  repassword: [
    {
      required: true,
      message: proxy.$t("loginInfo.userPassAgainTip"),
      trigger: "blur",
    },
  ],
});
const ruleForm = ref(null);
// 点击验证码 - 切换 图片事件

const submitForm = () => {
  ruleForm.value.validate(async (valid) => {
    if (valid) {
      const { message, type } = await forgetPass({
        ...form,
        lang: lang.value,
      });
      if (type == "ok") {
        Message({
          message: proxy.$t(message),
          type: "success",
        });
        emit("close");
      } else {
        Message({
          message: proxy.$t(message),
          type: "error",
        });
      }
    }
  });
};
</script>
<style lang="scss" scope></style>
