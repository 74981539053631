<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    :title="$t('userText.bindEmailText')"
    width="500px"
    @closed="handClose"
  >
    <div class="authDig">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item prop="email">
          <el-input
            v-model="form.email"
            :placeholder="$t('tips.inputNewEmail')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="form.code" :placeholder="$t('tips.getCodeText')">
            <i slot="prefix" class="el-input__icon el-icon-postcard"></i>
            <span slot="suffix" :class="{ notHand: hasSend }" @click="sendCode">
              <template v-if="hasSend">
                {{ countNum }}{{ $t("tips.reGetCodeText") }}
              </template>
              <template v-else> {{ $t("tips.getCodeText") }} </template>
            </span>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">{{
        $t("tips.cancelText")
      }}</el-button>
      <el-button size="small" type="primary" @click="submitForm">{{
        $t("tips.submitText")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import useDialog from "@/hooks/useDialog";
import { Message } from "element-ui";
import { real_name } from "@/api/user";
import { useLang } from "@/hooks/useLang";
import { getsms_mail, editEmai } from "@/api/common";
const { lang } = useLang();
const emit = defineEmits(["close", "resetEmail"]);
const { proxy } = getCurrentInstance();
const { visible, handClose } = useDialog(emit);
const form = reactive({
  email: null, // 邮箱
  code: "", // 码
});
const rules = ref({
  email: [
    {
      required: true,
      message: proxy.$t("tips.inputNewEmail"),
      trigger: "blur",
    },
  ],
  code: [
    {
      required: true,
      message: proxy.$t("tips.getCodeText"),
      trigger: "blur",
    },
  ],
});
const ruleForm = ref(null);
const hasSend = ref(false);
const countNum = ref(60);
const sendCode = async () => {
  if (hasSend.value) {
    return false;
  }
  if (!validateEmail(form.email)) {
    Message.warning(proxy.$t('tips.inputSureEmail'));
    return false;
  }
  const { state } = await getsms_mail({ user_string: form.email });
  if (state == 200) {
    hasSend.value = true;
    countDown();
  }
};
const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
// 点击验证码 - 切换 图片事件
const countDown = () => {
  let timer;
  timer = setInterval(() => {
    if (countNum.value == 0) {
      hasSend.value = false;
      countNum.value = 10;
      clearInterval(timer);
    }
    --countNum.value;
  }, 1000);
};
const submitForm = () => {
  ruleForm.value.validate(async (valid) => {
    if (valid) {
      const { message, type } = await editEmai({
        ...form,
        lang: lang.value,
      });
      if (type == "ok") {
        Message({
          message: proxy.$t(message),
          type: "success",
        });
        emit("resetEmail");
      } else {
        Message({
          message: proxy.$t(message),
          type: "error",
        });
      }
    }
  });
};
</script>
<style lang="scss" scope>
.authDig {
}
.avatar-uploader .el-upload {
  border: 1px dashed $market-list-hover;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
.el-input__suffix-inner {
  span {
    cursor: pointer;
    color: $coloe-text;
    position: relative;

    &.notHand {
      cursor: not-allowed;
    }

    &::before {
      position: absolute;
      content: "|";
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
