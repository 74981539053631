<template>
  <div class="bottomInfoList">
    <!-- 邮箱 -->
    <div class="lineItem">
      <span>{{ $t("userText.bindEmailText") }}</span>
      <div class="rightLine">
        <span>{{ email }}</span>
        <el-button size="small" @click="showEmial = true">{{
          $t("tips.editText")
        }}</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <!-- 登录密码 -->
    <div class="lineItem">
      <span>{{ $t("userText.loginPassText") }}</span>
      <el-button size="small" @click="showloginPass = true">{{
        $t("tips.editText")
      }}</el-button>
    </div>
    <el-divider></el-divider>
    <!-- 支付密码 -->
    <div class="lineItem">
      <span>{{ $t("userText.payPassText") }}</span>
      <el-button size="small" @click="showPayPass = true">{{
        $t("tips.editText")
      }}</el-button>
    </div>
    <el-divider></el-divider>
    <div class="lineItem">
      <span>{{ $t("headerText.aboutSelf") }}</span>
      <el-button
        size="small"
        @click="router.push('/innerPage/heloCenter?id=19')"
        >{{ $t("tips.viewText") }}</el-button
      >
    </div>
    <emailDig
      v-if="showEmial"
      @close="showEmial = false"
      @resetEmail="resetEmail"
    ></emailDig>
    <loginPassDig
      v-if="showloginPass"
      @close="showloginPass = false"
    ></loginPassDig>
    <payPassDig v-if="showPayPass" @close="showPayPass = false"></payPassDig>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, getCurrentInstance } from "vue";
import emailDig from "@/components/memberCenter/emailDig.vue";
import loginPassDig from "@/components/memberCenter/loginPassDig.vue";
import payPassDig from "@/components/memberCenter/payPassDig.vue";
import { useStore, useRouter } from "@/utils/vueApi";
const store = useStore();
const router = useRouter();
const vm = getCurrentInstance();

const email = computed(() => {
  return store.state.user.userInfo.email;
});
// 邮箱
const showEmial = ref(false);
const resetEmail = () => {
  store.dispatch("user/getUserInfo");
  showEmial.value = false;
};
// 登录密码
const showloginPass = ref(false);
// 支付密码
const showPayPass = ref(false);
onMounted(() => {
  store.dispatch("user/getUserInfo");
});
</script>

<style lang="scss" scoped>
.bottomInfoList {
  padding: 0 20px 20px;
  box-sizing: border-box;
  .lineItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    .rightLine {
      span {
        margin-right: 20px;
      }
    }
  }
}
</style>
