import { ref, toRaw } from "vue";

export default function useDialog(emit) {
  const visible = ref(false);
  const showModal = () => {
    visible.value = true;
  };
  showModal();
  const handClose = () => {
    emit("close");
  };
  return {
    visible,
    handClose,
  };
}
